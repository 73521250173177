import React from 'react';
import orangeRectangle from '../../assets/icons/orange-rectangle.svg';
import greenRectangle from '../../assets/icons/green-rectangle.svg';

import vestimentaYCalzado from '../../assets/icons/vestimenta-calzado.png';
import herramientas from '../../assets/icons/herramientas.png';
import sanidadAnimal from '../../assets/icons/sanidad-animal.png';
import maderaYAlambres from '../../assets/icons/madera-alambres.png';
import piscinas from '../../assets/icons/piscinas.png';
import lubricantes from '../../assets/icons/lubricantes.png';
import cercosYAgua from '../../assets/icons/cercos-agua.png';
import casaYJardin from '../../assets/icons/casa-jardin.png';
import nutricionAnimal from '../../assets/icons/nutricion-animal.png';
import cubiertasYBaterias from '../../assets/icons/cubiertas-baterias.png';

export const categoriesArray = [
  {
    title: 'Herramientas',
    icon: herramientas,
  },
  {
    title: 'Sanidad Animal',
    icon: sanidadAnimal,
  },
  {
    title: 'Madera y Alambres',
    icon: maderaYAlambres,
  },
  {
    title: 'Piscinas',
    icon: piscinas,
  },
  {
    title: 'Lubricantes',
    icon: lubricantes,
  },
  {
    title: 'Cercos y Agua',
    icon: cercosYAgua,
  },
  {
    title: 'Casa y Jardin',
    icon: casaYJardin,
  },
  {
    title: 'Nutricion Animal',
    icon: nutricionAnimal,
  },
  {
    title: 'Cubiertas y baterias',
    icon: cubiertasYBaterias,
  },
  {
    title: 'Vestimenta y Calzado',
    icon: vestimentaYCalzado,
  },
];

const Categories = () => {
  return (
    <div className="flex flex-col gap-2">
      <img alt="orange rectangle" src={orangeRectangle} className="w-full" />
      <img alt="green rectangle" src={greenRectangle} className="w-full" />

      <div className="px-6 md:px-10 w-fit self-center md:w-full grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-0 xl:gap-4">
        {categoriesArray.map((category) => (
          <div
            key={category.title}
            className="flex w-full h-20 items-center  px-10 py-2 gap-3"
          >
            <img
              alt={category.title}
              src={category.icon}
              className="w-14 xl:w-20"
            />
            <h3 className="uppercase font-bold text-lg xl:text-xl text-start">
              {category.title}
            </h3>
          </div>
        ))}
      </div>
      <img alt="green rectangle" src={greenRectangle} className="w-full" />
      <img alt="orange rectangle" src={orangeRectangle} className="w-full" />
    </div>
  );
};

export default Categories;

import cows from '../assets/images/cows.png';
import video from '../assets/videos/video-store.mp4';
import Categories from '../components/categories';
import Footer from '../components/footer';
import Header from '../components/header';

const Home = () => {
  return (
    <div>
      <Header />
      <img alt="cows" src={cows} className="z-0 w-full h-screen object-cover" />
      <Categories />
      <div className="bg-gradient-to-r from-green from-1% via-white via-70% md:via-50% to-light-orange to-90%">
        <video controls loop className="px-6 md:px-10 py-4 w-full">
          <source src={video} type="video/mp4" />
          Su navegador no soporta el tipo de video.
        </video>
        <Footer />
      </div>
    </div>
  );
};

export default Home;

import React from 'react';
import logo from '../../assets/icons/transparent-logo-camposhop.svg';

const Header = () => {
  return (
    <header className="absolute w-full z-10 flex justify-center bg-transparent px-6 md:px-10 py-4 items-center">
      <img alt="camposhop" src={logo} className="w-44 md:w-xxl-272 h-auto" />
    </header>
  );
};

export default Header;

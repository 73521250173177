import React from 'react';
import instagram from '../../assets/icons/instagram.png';
import whatsapp from '../../assets/icons/whatsapp.png';
import email from '../../assets/icons/email.png';
import location from '../../assets/icons/location-pin.png';

const Footer = () => {
  return (
    <footer className="min-h-xxl-200 w-full flex flex-col px-6 md:px-10 py-6 gap-4">
      <h3 className="font-semibold text-3xl md:text-4xl mb-6 text-green uppercase font-cocogooseM">
        Contacto
      </h3>
      <div className="flex flex-col md:flex-row flex-wrap items-start justify-between gap-y-4">
        <div className="flex items-center gap-4">
          <img alt="email" src={email} className="w-6 md:w-10 h-auto" />
          <a
            className="font-medium text-lg text-blue-800"
            href="mailto:info@camposhop.com.uy?subject=Consulta&body=Hola, quisiera mas informacion sobre sus productos."
          >
            info@camposhop.com.uy
          </a>
        </div>
        <div className="flex items-start gap-4">
          <img alt="instagram" src={instagram} className="w-6 md:w-10 h-auto" />
          <div className="flex flex-col items-start gap-1">
            <a
              href="https://www.instagram.com/camposhop_ok/"
              className="font-medium text-blue-800 text-lg"
            >
              camposhop_ok
            </a>
            <a
              href="https://www.instagram.com/camposhop_store/"
              className="font-medium text-blue-800 text-lg"
            >
              camposhop_store
            </a>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <img alt="whatsapp" src={whatsapp} className="w-6 md:w-10 h-auto" />
          <a
            href="https://wa.me/+59892474424?text=Hola%20como%20estas?%20Quiero%20saber%20mas%20sobre%20tus%20productos"
            className="font-medium text-blue-800 text-lg"
          >
            Haz click para chatear
          </a>
        </div>
        <div className="flex items-center gap-4">
          <img
            alt="location"
            color="#ffff"
            src={location}
            className="w-6 md:w-10 h-auto"
          />
          <a
            className="font-medium text-blue-800 text-start text-lg"
            href="https://www.google.com/maps/place/Agroveterinaria+Camposhop/@-33.5220354,-58.2234237,20.17z/data=!4m12!1m5!3m4!2zMzPCsDMxJzE5LjYiUyA1OMKwMTMnMjQuMiJX!8m2!3d-33.522115!4d-58.2233958!3m5!1s0x95a52988588c1a95:0x88bcde66b4bdc0d7!8m2!3d-33.5219766!4d-58.2234448!16s%2Fg%2F11sjd5chdk?hl=es&entry=ttu"
          >
            Asencio 1100, Dolores Soriano.
          </a>
          <p className="font-medium text-lg"></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
